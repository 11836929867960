import React, { useState, useRef, useEffect } from 'react';
import Header from './components/Header';
import TraitSelection from './components/TraitSelection';
import ArtDisplay from './components/ArtDisplay';
import Footer from './components/Footer';
import RulesModal from './components/RulesModal';
import metadata from './Metadata.json';
import './App.css';
import traits, { rules } from './traits'; // Adjust the path according to your file structure

const baseLayersOrder = ["background", "fur", "clothing", "eyes", "head", "mouth", "funAddOns", "curated"];
const dropdownOrder = ["mouth", "head", "eyes", "clothing", "fur", "background", "curated"];

function App() {
  const defaultTraits = {
    background: 'None',
    fur: 'Midnight', 
    clothing: 'Crafting Apron', 
    eyes: 'None',
    head: 'Chef Hat',
    mouth: 'None',
    funAddOns: 'None', 
    curated: 'None', 
  };

  const [selectedTraits, setSelectedTraits] = useState(defaultTraits);
  const [tokenID, setTokenID] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [showRules, setShowRules] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false); // <--- ADD THIS LINE
  const artRef = useRef(null);

  useEffect(() => {
    const checkForMatch = () => {
      // If any "Fun Add-On" trait is selected, there should be no match
      if (selectedTraits.funAddOns !== "None") {
        setTokenID(null);
        setMatchType(null);
        return;
      }

      // If a "Curated" trait is selected, match based only on the "Curated Canine" trait in metadata
      if (selectedTraits.curated !== "None") {
        const matchedToken = metadata.find((item) => {
          const curatedTrait = item.attributes.find(attr => attr.trait_type === "Curated Canine");
          return curatedTrait && curatedTrait.value === selectedTraits.curated;
        });

        if (matchedToken) {
          setTokenID(matchedToken.JsonTokenID);
          setMatchType('exact');
        } else {
          setTokenID(null);
          setMatchType(null);
        }
        return;
      }

      // If no "Curated" trait is selected, match based on all other traits
      const currentTraits = {
        Background: selectedTraits.background,
        Fur: selectedTraits.fur,
        Clothing: selectedTraits.clothing,
        Eyes: selectedTraits.eyes,
        Head: selectedTraits.head,
        Mouth: selectedTraits.mouth,
      };

      const matchedToken = metadata.find((item) => {
        const itemTraits = item.attributes.reduce((acc, attribute) => {
          acc[attribute.trait_type] = attribute.value;
          return acc;
        }, {});

        const isExactMatch = Object.keys(currentTraits).every(
          (key) => currentTraits[key] === itemTraits[key]
        );

        const isTwinMatch = Object.keys(currentTraits).every(
          (key) => key === 'Background' || currentTraits[key] === itemTraits[key]
        );

        if (isExactMatch) {
          setMatchType('exact');
          return item;
        } else if (isTwinMatch) {
          setMatchType('twin');
          return item;
        } else {
          return null;
        }
      });

      if (matchedToken) {
        setTokenID(matchedToken.JsonTokenID);
      } else {
        setTokenID(null);
        setMatchType(null);
      }
    };

    checkForMatch();
  }, [selectedTraits]);

  const handleTraitChange = (trait, value) => {
    setSelectedTraits((prev) => {
      const updatedTraits = { ...prev, [trait]: value };

      if (trait === "curated" && value !== "None") {
        Object.keys(updatedTraits).forEach((key) => {
          if (key !== "curated") {
            updatedTraits[key] = "None"; 
          }
        });
        updatedTraits.fur = "Midnight";  
        updatedTraits.clothing = "Naked"; 
        updatedTraits.head = "None";      
      } else if (trait !== "curated" && prev.curated !== "None") {
        updatedTraits["curated"] = "None";
      }

      if (trait === "fur" && prev.funAddOns === "GM Mug") {
        updatedTraits["funAddOns"] = "GM Mug";
      }

      return updatedTraits;
    });
  };

  // Function to reset the traits to the default state
  const resetTraits = () => {
    setSelectedTraits(defaultTraits);
  };

const randomizeTraits = () => {
  const randomizedTraits = Object.keys(selectedTraits).reduce((acc, traitType) => {
    const options = traits[traitType];

    if (traitType === "funAddOns" || traitType === "curated") {
      acc[traitType] = "None";
    } else {
      let validOptions = options.filter((option) => {
        return Object.keys(acc).every(
          (selectedType) =>
            (rules[option] ? !rules[option].includes(acc[selectedType]) : true) &&
            (rules[acc[selectedType]] ? !rules[acc[selectedType]].includes(option) : true)
        );
      });

      const randomTrait = validOptions[Math.floor(Math.random() * validOptions.length)];
      acc[traitType] = randomTrait;
    }

    return acc;
  }, {});

  setSelectedTraits(randomizedTraits);
};

  // Function to handle Token ID input and set traits based on metadata
  const handleTokenIDChange = (tokenId) => {
    if (tokenId < 1 || tokenId > 420) return; 

    const matchedToken = metadata.find((item) => item.JsonTokenID === tokenId);

    if (matchedToken) {
      const curatedTrait = matchedToken.attributes.find(attr => attr.trait_type === "Curated Canine");

      if (curatedTrait) {
        setSelectedTraits({
          background: 'None',
          fur: 'Midnight',
          clothing: 'Naked', 
          eyes: 'None',
          head: 'None',      
          mouth: 'None',
          funAddOns: 'None',
          curated: curatedTrait.value,
        });
      } else {
        const newTraits = matchedToken.attributes.reduce((acc, attribute) => {
          const traitType = attribute.trait_type.toLowerCase();
          acc[traitType] = attribute.value;
          return acc;
        }, {});

        setSelectedTraits((prev) => ({
          ...prev,
          ...newTraits,
          curated: 'None', 
        }));
      }
    }
  };

  const getGMMugTrait = (fur) => {
    switch (fur) {
      case 'Arctic':
        return 'Arctic GM';
      case 'Espresso':
        return 'Espresso GM';
      case 'Frosted':
        return 'Frosted GM';
      case 'Golden':
        return 'Golden GM';
      case 'Midnight':
        return 'Midnight GM';
      case 'Collab - Seiyan':
        return 'Collab - Seiyan GM';
      default:
        return 'None';
    }
  };


    const getBeerTrait = (fur) => {
        switch (fur) {
            case 'Arctic':
                return 'Arctic Beer';
            case 'Espresso':
                return 'Espresso Beer';
            case 'Frosted':
                return 'Frosted Beer';
            case 'Golden':
                return 'Golden Beer';
            case 'Midnight':
                return 'Midnight Beer';
            case 'Collab - Seiyan':
                return 'Collab - Seiyan Beer';
            default:
                return 'None';
        }
    };


    const getImagePath = (traitType, trait) => {
        if (traitType === "curated" && trait !== "None") {
            return `/Layers/curated/${trait.replace(/ /g, '%20')}.png`;
        }
        if (traitType === "funAddOns") {
            if (trait === "GM Mug") {
                const mugFileName = getGMMugTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${mugFileName}.png`;
            }
            if (trait === "Beer") {
                const beerFileName = getBeerTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${beerFileName}.png`;
            }
            if (trait !== "None") {
                return `/Layers/paw/${trait.replace(/ /g, '%20')}.png`;
            }
        }
        return `/Layers/${traitType}/${trait.replace(/ /g, '%20')}.png`;
    };

  const downloadImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');

    const layersOrder = selectedTraits.curated !== "None" ? ["curated"] : baseLayersOrder;

    const images = layersOrder.map((layer) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = getImagePath(layer, selectedTraits[layer]);
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 800, 800);
          resolve();
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${img.src}`);
          resolve();
        };
      });
    });

    Promise.all(images).then(() => {
      const link = document.createElement('a');
      link.download = 'nft-art.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  const copyToClipboard = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const layersOrder = selectedTraits.curated !== "None" ? ["curated"] : baseLayersOrder; 

    const drawLayer = (traitType, trait) => {
      return new Promise((resolve) => {
        if (!trait || trait === 'None') {
          resolve(); 
          return;
        }

        const img = new Image();
        img.src = getImagePath(traitType, trait); 
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 800, 800);
          resolve();
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${img.src}`);
          resolve();
        };
      });
    };

    const drawAllLayers = layersOrder.map((traitType) => {
      const trait = selectedTraits[traitType];
      return drawLayer(traitType, trait);
    });

    Promise.all(drawAllLayers).then(() => {
      canvas.toBlob((blob) => {
        if (navigator.clipboard && window.ClipboardItem) {
          const item = new window.ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]).then(() => {
            setCopySuccess(true); 
            setTimeout(() => setCopySuccess(false), 2000); 
          }).catch((err) => {
            console.error('Failed to copy image to clipboard:', err);
          });
        } else {
          console.error('Clipboard API not supported');
        }
      });
    });
  };

  return (
    <div className="App">
      <Header />
      <div className="container">
        <TraitSelection
          dropdownOrder={dropdownOrder}
          layersOrder={baseLayersOrder}
          selectedTraits={selectedTraits}
          handleTraitChange={handleTraitChange}
          downloadImage={downloadImage}
          copyToClipboard={copyToClipboard}
          resetTraits={resetTraits}
          randomizeTraits={randomizeTraits}
          setShowRules={setShowRules}
          handleTokenIDChange={handleTokenIDChange}
          copySuccess={copySuccess} // Pass copySuccess state to TraitSelection
        />
        <ArtDisplay
          layersOrder={baseLayersOrder}
          selectedTraits={selectedTraits}
          artRef={artRef}
          tokenID={tokenID}
          matchType={matchType}
        />
      </div>
      <Footer />
      {showRules && <RulesModal setShowRules={setShowRules} />}
    </div>
  );
}

export default App;
