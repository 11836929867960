import traits from './traits.json';

export const rules = {
  "Decoder Goggles": ["Crown", "Red Partyhat", "Seilor Hat", "Red Seiyan", "Gold Seiyan", "Chef Hat", "Pirate Hat","Curated - TOSHI Mask"],
  "Laser Eyes": ["Crown", "Gold Seiyan", "Red Seiyan", "Blunt", "Bone", "Cigar", "Flame", "Golden Bone", "Paint Brush", "Mustache", "Trident"],
  "3D Glasses": ["Pirate Hat", "Seilor Hat", "Crown","Curated - TOSHI Mask"],
  "Red Partyhat": ["Ellie's Shades"],
  "Crown": ["Decoder Goggles", "Laser Eyes", "3D Glasses", "Ellie's Shades", "Robin Mask", "Sunglasses"],
  "Red Partyhat": ["Decoder Goggles"],
  "Seilor Hat": ["Decoder Goggles", "3D Glasses"],
  "Red Seiyan": ["Decoder Goggles", "Laser Eyes"],
  "Gold Seiyan": ["Decoder Goggles", "Laser Eyes"],
  "Chef Hat": ["Decoder Goggles"],
  "Pirate Hat": ["Decoder Goggles", "3D Glasses"],
  "Blunt": ["Laser Eyes"],
  "Bone": ["Laser Eyes"],
  "Cigar": ["Laser Eyes"],
  "Flame": ["Laser Eyes"],
  "Golden Bone": ["Laser Eyes"],
  "Paint Brush": ["Laser Eyes"],
  "Mustache": ["Laser Eyes"],
  "Trident": ["Laser Eyes"],
  "Ellie's Shades": ["Red Partyhat", "Crown"],
  "Robin Mask": ["Crown"],
  "Sunglasses": ["Crown","Curated - TOSHI Mask"],
  "Holiday - Pumpkin Head": ["Decoder Goggles", "Laser Eyes", "3D Glasses","Sunglasses", "Ellie's Shades", "Robin Mask", "Curated - Inspector Goggles"],
  "Holiday - Devil Onesei": ["Holiday - Pumpkin Head","Collab - Emperor Crown","3D Glasses","Curated - Inspector Goggles","Decoder Goggles","Ellie's Shades","Laser Eyes","Robin Mask","Sunglasses","Chef Hat","Crown","Curated - TOSHI Mask","Red Seiyan","Gold Seiyan","Halo","Holiday - Devil Horns","Laurel Wreath","Pirate Hat","Red Partyhat","Rhino Horn","Seilor Hat","Unicorn Horn","Holiday - Santa Hat"],
  "Holiday - Devil Horns": ["3D Glasses","Decoder Goggles","Ellie's Shades","Sunglasses"],
  "Collab - Emperor Crown": ["3D Glasses","Decoder Goggles","Ellie's Shades","Sunglasses","Laser Eyes","Curated - Inspector Goggles"],
  "Collab - Spectre": ["Holiday - Devil Horns","3D Glasses","Curated - Inspector Goggles","Decoder Goggles","Ellie's Shades","Laser Eyes","Robin Mask","Sunglasses"],
  "Custom - Flamingo": ["Holiday - Devil Horns","3D Glasses","Curated - Inspector Goggles","Decoder Goggles","Ellie's Shades","Laser Eyes","Robin Mask","Sunglasses","Chef Hat","Crown","Red Seiyan","Gold Seiyan","Halo","Laurel Wreath","Pirate Hat","Red Partyhat","Rhino Horn","Seilor Hat","Unicorn Horn","Collab - Emperor Crown","Curated - TOSHI Mask","Holiday - Pumpkin Head","Holiday - Devil Horns","Holiday - Santa Hat"],
  "Collab - Gonads": ["Chef Hat","Crown","Red Seiyan","Gold Seiyan","Laurel Wreath","Pirate Hat","Red Partyhat","Collab - Emperor Crown","Curated - TOSHI Mask","Holiday - Pumpkin Head","Holiday - Devil Horns","Holiday - Santa Hat"]
};

export default traits;
