import React, { useState } from 'react';
import traits, { rules } from '../traits'; // Import traits and rules
import { FaTwitter, FaMugHot, FaDice, FaRedo, FaDownload, FaSearch } from 'react-icons/fa'; // Import the icons

const TraitSelection = ({
  selectedTraits,
  handleTraitChange,
  downloadImage,
  copyToClipboard,
  resetTraits,
  randomizeTraits,
  setShowRules,
  handleTokenIDChange,
  copySuccess,
}) => {
  const [activeTab, setActiveTab] = useState('background');  
  const [tokenInput, setTokenInput] = useState('');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (traitType, value) => {
    if (value !== "None" && rules[value]) {
      Object.keys(selectedTraits).forEach((key) => {
        if (rules[value].includes(selectedTraits[key])) {
          handleTraitChange(key, "None");
        }
      });
    }
    handleTraitChange(traitType, value);
  };

  const handleTokenInputChange = (event) => {
    let input = event.target.value;
    if (!isNaN(input) && input !== "") {
      const tokenNumber = Math.max(1, Math.min(420, parseInt(input)));
      setTokenInput(tokenNumber);
      handleTokenIDChange(tokenNumber);
    } else {
      setTokenInput(""); 
    }
  };

  const adjustedRandomizeTraits = () => {
    randomizeTraits();
    handleTraitChange("curated", "None");
  };

  const getGMMugTrait = (fur) => {
    switch (fur) {
      case 'Arctic': return 'Arctic GM';
      case 'Espresso': return 'Espresso GM';
      case 'Frosted': return 'Frosted GM';
      case 'Golden': return 'Golden GM';
      case 'Midnight': return 'Midnight GM';
      case 'Collab - Seiyan': return 'Collab - Seiyan GM';
      default: return 'None';
    }
    };


    const getBeerTrait = (fur) => {
        switch (fur) {
            case 'Arctic':
                return 'Arctic Beer';
            case 'Espresso':
                return 'Espresso Beer';
            case 'Frosted':
                return 'Frosted Beer';
            case 'Golden':
                return 'Golden Beer';
            case 'Midnight':
                return 'Midnight Beer';
            case 'Collab - Seiyan':
                return 'Collab - Seiyan Beer';
            default:
                return 'None';
        }
    };


    const getImagePath = (traitType, trait) => {
        if (traitType === 'curated' && trait !== 'None') {
            return `/Layers/curated/${trait.replace(/ /g, '%20')}.png`;
        }
        if (traitType === 'funAddOns') {
            if (trait === 'GM Mug') {
                const mugFileName = getGMMugTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${mugFileName}.png`;
            }
            if (trait === 'Beer') {
                const beerFileName = getBeerTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${beerFileName}.png`;
            }
            if (trait !== 'None') {
                return `/Layers/paw/${trait.replace(/ /g, '%20')}.png`;
            }
        }
        if (trait === 'None') {
            return null;
        }
        return `/Layers/${traitType}/${trait.replace(/ /g, '%20')}.png`;
    };

  const tweetGMMessage = () => {
    const tweetText = `GM from Crafty Canines! Craft your own using our fun art generation tool! craft.craftycanines.xyz`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };

  const tabs = Object.keys(traits).map(traitType => ({
    label: traitType === 'funAddOns' ? 'Fun Add Ons' : traitType.charAt(0).toUpperCase() + traitType.slice(1),
    traitType
  }));

  const getFilteredTraits = (traitType) => {
    const selectedTraitValues = Object.values(selectedTraits);
    return traits[traitType]?.map((trait) => {
      const isConflicting = selectedTraitValues.some(
        (selectedTrait) =>
          (rules[selectedTrait] && rules[selectedTrait].includes(trait)) ||
          (rules[trait] && rules[trait].includes(selectedTrait))
      );
      return { trait, isConflicting };
    }) || [];
  };

  return (
    <div className="trait-selection-container">
      <div className="trait-selection" style={{ width: '700px' }}>
        <div className="info-button">
          <a href="#!" onClick={() => setShowRules(true)} className="rules-link">ℹ️</a>
        </div>

<div className="tabs">
  {tabs.map((tab) => (
    <button
      key={tab.label}
      className={`tab-button ${activeTab === tab.traitType ? 'active-tab' : ''}`}
      onClick={() => handleTabChange(tab.traitType)}
    >
      {tab.label}
    </button>
  ))}
</div>


        <div
          className="traits-grid"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: '1px',
            backgroundColor: '#2c3e50',
            padding: '10px',
            height: '400px',
            overflowY: 'auto',
          }}
        >
          {getFilteredTraits(activeTab).map(({ trait, isConflicting }) => (
            <div
              key={trait}
              className="trait"
              onClick={() => !isConflicting && handleChange(activeTab, trait)}
              style={{
                position: 'relative',
                border: selectedTraits[activeTab] === trait ? '3px solid green' : '2px solid #2980b9',
                cursor: isConflicting ? 'not-allowed' : 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '100px',
                margin: '0 auto',
              }}
            >
              {getImagePath(activeTab, trait) && (
                <img
                  src={getImagePath(activeTab, trait)}
                  alt={trait}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              )}
              {isConflicting && (


<div
  style={{
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 0, 0, 0.2)', // Full background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
 
  <div
    style={{
      position: 'absolute',
      top: '5px',
      fontSize: '1rem',
    }}
  >
    ❌
  </div>
</div>


              )}
            </div>
          ))}
        </div>
      </div>
	  
	  
	  
<div className="bottom-card" style={{ 
    width: '700px', 
    backgroundColor: '#34495e', 
    borderRadius: '10px', 
    textAlign: 'center'
}}>

  <div className="buttons" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
    <button onClick={adjustedRandomizeTraits} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <FaDice /> Randomize
    </button>
    <button onClick={resetTraits} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <FaRedo /> Reset
    </button>
    <button onClick={downloadImage} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <FaDownload /> Download
    </button>
    <button onClick={copyToClipboard} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {copySuccess ? '✔️' : '📋'}
    </button>
    <button onClick={tweetGMMessage} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <FaTwitter />|<FaMugHot />
    </button>

<div className="token-input" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
  <FaSearch style={{ fontSize: '1.2em', color: '#ecf0f1' }} /> {/* Magnifying glass icon */}
  <label style={{ marginRight: '5px' }}>ID Lookup:</label>
  <input
    type="number"
    min="1"
    max="420"
    value={tokenInput}
    onChange={handleTokenInputChange}
    placeholder="###"
    style={{
      padding: '5px',
      fontSize: '1em',
      textAlign: 'center',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      color: 'white',
      border: '2px solid white',
      width: '100px',
      boxSizing: 'border-box',
    }}
  />
</div>

  </div>
</div>
	  
	  
    </div>
  );
};

export default TraitSelection;
