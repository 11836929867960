import React, { useEffect, useRef } from 'react';

const RulesModal = ({ setShowRules }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowRules(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
<div className="overlay">
  <div className="rules-modal" ref={modalRef}>
    <h2>Trait Combination Rules</h2>
    <p>
      Traits that conflict or don’t visually complement each other will be highlighted with a ❌ and grayed out, making them unavailable for selection.
    </p>
    <button onClick={() => setShowRules(false)}>Close</button>
  </div>
</div>

  );
};

export default RulesModal;
