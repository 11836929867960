import React, { useEffect, useRef } from 'react';

const ArtDisplay = ({ layersOrder, selectedTraits, artRef, tokenID, matchType }) => {
    const canvasRef = useRef(null);

    const getImagePath = (traitType, trait) => {
        if (traitType === "funAddOns") {
            if (trait === "GM Mug") {
                const mugFileName = getGMMugTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${mugFileName}.png`;
            }
            if (trait === "Beer") {
                const beerFileName = getBeerTrait(selectedTraits.fur).replace(/ /g, '%20');
                return `/Layers/paw/${beerFileName}.png`;
            }
            if (trait !== "None") {
                return `/Layers/paw/${trait.replace(/ /g, '%20')}.png`;
            }
        }
        return `/Layers/${traitType}/${trait.replace(/ /g, '%20')}.png`;
    };


    const getGMMugTrait = (fur) => {
        switch (fur) {
            case 'Arctic':
                return 'Arctic GM';
            case 'Espresso':
                return 'Espresso GM';
            case 'Frosted':
                return 'Frosted GM';
            case 'Golden':
                return 'Golden GM';
            case 'Midnight':
                return 'Midnight GM';
            case 'Collab - Seiyan':
                return 'Collab - Seiyan GM';
            default:
                return 'None';
        }
    };

    const getBeerTrait = (fur) => {
        switch (fur) {
            case 'Arctic':
                return 'Arctic Beer';
            case 'Espresso':
                return 'Espresso Beer';
            case 'Frosted':
                return 'Frosted Beer';
            case 'Golden':
                return 'Golden Beer';
            case 'Midnight':
                return 'Midnight Beer';
            case 'Collab - Seiyan':
                return 'Collab - Seiyan Beer';
            default:
                return 'None';
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 400;
        canvas.height = 400;

        // Clear the canvas before redrawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Sequentially draw layers in order
        const drawLayersSequentially = async () => {
            for (let layer of layersOrder) {
                const trait = selectedTraits[layer];
                if (trait === "None") continue;

                const image = new Image();
                image.src = getImagePath(layer, trait);

                // Wait for image to load before drawing it
                await new Promise((resolve) => {
                    image.onload = () => {
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        resolve();
                    };
                });
            }
        };

        drawLayersSequentially();
    }, [layersOrder, selectedTraits]);

    return (
        <div className="art-display-container">
            {/* Display Canvas */}
            <canvas ref={canvasRef} className="art-display" />

            <div className="match-message">
                {tokenID !== null && matchType === 'exact' && (
                    <>
                        <div className="token-id">
                            This is an exact match to token ID {tokenID}
                        </div>
                        <a
                            href={`https://pallet.exchange/collection/crafty-canines/${tokenID}`}
                            className="pallet-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Buy on Pallet Exchange
                        </a>
                    </>
                )}
                {tokenID !== null && matchType === 'twin' && (
                    <div className="token-id">This is a twin of token ID {tokenID}</div>
                )}
            </div>

            <div className="selected-traits">
                <h3>Selected Traits</h3>
                <div className="traits-list">
                    {Object.keys(selectedTraits).map((traitType) => (
                        <div key={traitType} className="trait-item">
                            <strong>{traitType.charAt(0).toUpperCase() + traitType.slice(1)}:</strong> {selectedTraits[traitType]}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ArtDisplay;
